<template>
  <div class="divider"></div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
.divider {
  background-color: map-get($lightSurface, level2);
  width: 100%;
  display: flex;
  height: 1px;
}
</style>